<template>
  <div>
    <div class="border-0 mb-xl-3 mb-1">
      <img
        src="@/assets/logo_b.png"
        style="width: 200px; height: 100px; margin: 10px auto; cursor: pointer"
        @click="this.$router.push('/')"
        :title="$t('go_home')"
      />

      <form method="POST" @submit="checkForm" ref="login" class="login_form">
        <div class="form-floating mb-3">
          <img src="@/assets/img/main/userid.png" class="login_left_img" />
          <input
            type="text"
            autocomplete="on"
            ref="uid"
            v-model="uid"
            tabindex="0"
            aria-describedby=""
            maxlength="10"
            minlength="4"
            placeholder="  "
            required="required"
            id="uid"
            class="form-control"
          />
          <label for="uid" class="text-secondary">{{
            $t("text_userid")
          }}</label>
        </div>

        <div class="form-floating mb-3">
          <img src="@/assets/img/main/pw.png" class="login_left_img" />
          <input
            type="password"
            ref="pwd"
            v-model="pwd"
            maxlength="12"
            minlength="6"
            placeholder="  "
            required="required"
            id="pwd"
            class="form-control"
          />
          <label for="pwd" class="text-secondary">{{
            $t("passwordTitle")
          }}</label>
        </div>

        <div class="form-floating mb-3">
          <img src="@/assets/img/main/captcha.png" class="login_left_img" />
          <input
            type="text"
            ref="captcha"
            v-model="captcha"
            maxlength="4"
            placeholder="  "
            required="required"
            id="captcha"
            class="form-control"
          />
          <label for="captcha" class="text-secondary">{{
            $t("text_Captcha")
          }}</label>
          <captchaview ref="captgetcode" />
        </div>

        <input
          type="submit"
          class="col-12 mt-3 text-white border-0 rounded-2 login_btn fw-bolder"
          :value="$t('login')"
          :disabled="this.pwd.length > 5 && this.uid.length > 3 ? false : true"
          :style="
            this.pwd.length > 5 && this.uid.length > 3 ? '' : 'cursor: no-drop;'
          "
        />
      </form>
      <div class="login_or_line">
        <div class="d-flex flex-row">
          <div class="login_line flex-grow-1 position-relative"></div>
          <div class="mx-3">{{ $t("Member_or") }}</div>
          <div class="login_line flex-grow-1 position-relative"></div>
        </div>
      </div>

      <!-- <div class="mb-2">
        <button
          class="border-0 d-inline-block p-0 position-relative text-primary bg-body pointer-events bg-transparent"
          type="button"
        >
          <img
            class="login_fb_img d-inline-block position-relative align-baseline"
            src="@/assets/img/main/messenger.png"
          />
          <span class="login_fb_text fw-bolder">{{
            $t("Member_fb_login")
          }}</span>
        </button>
      </div> -->

      <a class="login_forget" @click="link('service')">
        <font>{{ $t("forgetpwd") }}</font>
      </a>
    </div>

    <div class="border-0 mb-3 fs-tenfive" style="padding: 15px 40px">
      <!-- <p class="m-0 fw-bolder">
        {{ $t("already") }}
      </p> -->
      <router-link to="/Login/R" class="login_forget">
        <font
          class="fs-tenfive fw-bolder"
          style="color: #1a1080; font-weight: 600"
          >{{ $t("register") }}</font
        >
      </router-link>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import api from "../../components/http/api";
import captchaview from "../Home/captcha.vue";

export default {
  name: "loginpart",
  data() {
    return {
      uid: "",
      pwd: "",
    };
  },
  watch: {
    pwd: function () {
      this.pwd = this.pwd.replace(/[^A-Za-z0-9]/g, "");
    },
    uid: function () {
      this.uid = this.uid.replace(/[^A-Za-z0-9]/g, "");
    },
  },
  async created() {
    await this.GetIP();
    if (localStorage.getItem("ip") != null) {
      if (localStorage.getItem("ip").length < 7) {
        this.$MSG.API_popmsg(
          "IP is error, Please try again or change another network",
          "",
          "error"
        );
      }
    }
  },
  mounted() {},
  methods: {
    async GetIP() {
      await api.getIP().then((res) => {
        localStorage.setItem("ip", res);
      });
    },
    checkForm: function (e) {
      e.preventDefault();
      if (this.checkcode()) {
        localStorage.setItem("device", this.$Utils.isMobile());
        let param = {
          action: "Login",
          body: {
            uid: this.uid,
            pwd: this.pwd,
            ip: localStorage.getItem("ip"),
            device: localStorage.getItem("device") + ", " + navigator.userAgent,
            token: "",
            regdomain: document.location.origin,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          var status = res.status;
          if (status == "success") {
            Cookies.set("code", res.session_code);
            localStorage.setItem("uid", this.uid);
            localStorage.setItem("utype", res.utype);
            localStorage.setItem("big_logo", res.logo);
            if (res.bankcard == null || res.bankcard == "") {
              localStorage.setItem("first", "first");
              this.$store.commit("ADDCARD", true);
            }
            this.$router.push({ name: "Home" });
          } else {
            this.$MSG.API_popmsg(res.msg, "", "error");
          }
        });
      } else {
        this.$MSG.API_popmsg(this.$t("text_error_cap"), "", "error");
      }
    },
    checkcode: function () {
      return this.$refs.captgetcode.getdata() == this.captcha ? true : false;
    },
    link: function (e) {
      var url = "";
      switch (e) {
        case "service":
          url = this.$store.state.service.qq.qq;
          break;
        default:
          url = "";
          break;
      }
      window.open(url);
    },
  },
  computed: {
    ...mapGetters(["service"]),
  },
  components: { captchaview },
};
</script>
